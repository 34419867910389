
#production-plan-gantt-chart {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.gantt_task_line.gantt_project .gantt_task_content {
    color: #fff;
}

.gantt_task_line.project-overdue {
    background-color: #ff2200;
    border-color: #ff2200;
}

.gantt_task_line.project-overdue .gantt_task_progress {
    background-color: #990000;
    border-color: #990000;
}

.gantt_task_line.task-ordered {
    background-color: #0099ee;
    border-color: #0099ee;
}

.gantt_task_line.task-ordered .gantt_task_progress {
    background-color: #0033ee;
    border-color: #0033ee;
}

.gantt_task_line.task-not-ordered {
	background-color: #ee99cc;
	border-color: #ee99cc;
}

.gantt_task_line.task-not-started {
	background-color: #8855ee;
	border-color: #8855ee;
}

.folder_row {
    font-weight: bold;
}

.gantt_row.highlighted_resource,
.gantt_row.odd.highlighted_resource {
    background-color: rgba(255, 251, 224, 0.6);
}

.gantt_task_row.highlighted_resource, 
.gantt_task_row.odd.highlighted_resource {
    background-color: rgba(255, 251, 224, 0.6);
}

.gantt_task_cell.week_end {
    background-color: #e8e8e87d;
}

.gantt_task_row.gantt_selected .gantt_task_cell.week_end {
    background-color: #e8e8e87d !important;
}

.group_row,
.group_row.odd,
.gantt_task_row.group_row {
    background-color: rgba(232, 232, 232, 0.6);
}

.owner-label {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background: #e6e6e6;
    color: #6f6f6f;
    margin: 0 3px;
    font-weight: bold;
}


.resource-select-panel .gantt_layout_content {
    line-height: 35px;
    text-align: right;
    padding-right: 15px;
    overflow: hidden !important;
}
.resource-select {
    padding: 3px;
    margin-left: 13px;
}

.column_overload .gantt_histogram_fill {
    background-color:#ffa9a9;
}

.gantt_marker.today{
    background: #663300;
}

.orderTime {
    position: absolute;
    width: 20px;
    height: 22px;
    margin-left: -8px;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon style='fill:%239966ff;stroke:%23ffffff;' points='0,0 16,0 8,16' /%3E%3C/svg%3E%0A");
}

.deadline {
    position: absolute;
    width: 20px;
    height: 22px;
    margin-left: -8px;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon style='fill:%23ff0000;stroke:%23ffffff;' points='0,0 16,0 8,16' /%3E%3C/svg%3E%0A");
}

.actual-start-date {
    position: absolute;
    width: 10px;
    height: 22px;
    margin-top: 8px;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon style='fill:%23000000' points='0,11 5,5 5,17' /%3E%3C/svg%3E%0A");
}

.actual-end-date {
    position: absolute;
    width: 10px;
    height: 22px;
    margin-top: 8px;
    margin-left: -10px;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon style='fill:%23000000' points='5,5 5,17 10,11' /%3E%3C/svg%3E%0A");
}

.actual-end-date-not-complete {
    position: absolute;
    width: 10px;
    height: 22px;
    margin-top: 8px;
    margin-left: -10px;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon style='fill:%23ffffff;stroke:%23000000;stroke-width: 1' points='5,5 5,17 10,11' /%3E%3C/svg%3E");
}

.work-time-line {
    position: absolute;
    height: 12px;
    margin-top: 8px;
    margin-left: 0;
    margin-right: 0;
    z-index: 1;
    border-bottom: #000000 dotted 1px; 
}

.overdue-indicator {
    width: 18px;
    margin-top: 8px;
    height: 18px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 17px;
    color: white;
    background: rgb(255, 60, 60);
    line-height: 20px;
    text-align: center;
    font-size: 16px;
}

.gantt_row, .gantt_task_row {
    border-bottom: 1px solid #ebebeb;
    background-color: #fff
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected {
    background-color: #3f50b5;
}

.gantt_grid_data .gantt_row.gantt_selected .gantt_tree_content,
.gantt_grid_data .gantt_row.odd.gantt_selected  .gantt_tree_content {
    color: #ffffff
}

.gantt_task_row.gantt_selected {
    background-color: #ffffff;
}

.gantt_task_row.gantt_selected  .gantt_tree_content {
    color: #000000
}

.gantt_grid_data .gantt_row.gantt_selected:hover,
.gantt_grid_data .gantt_row.odd.gantt_selected:hover {
    background-color: #3f50b5;
}

.gantt_grid_data .gantt_row.gantt_selected:hover .gantt_tree_content,
.gantt_grid_data .gantt_row.odd.gantt_selected:hover  .gantt_tree_content {
    color: #ffffff
}

.gantt_task_row.gantt_selected:hover {
    background-color: #ffffff;
}

.gantt_task_row.gantt_selected:hover  .gantt_tree_content {
    color: #000000
}

.gantt_grid_data .gantt_row:hover,
.gantt_grid_data .gantt_row.odd:hover,
.gantt_task_row:hover {
    background-color: #ffff;
}

.gantt_grid_data .gantt_row:hover .gantt_tree_content,
.gantt_grid_data .gantt_row.odd:hover  .gantt_tree_content,
.gantt_task_row:hover  .gantt_tree_content {
    color: #000000
}

.gantt_histogram_label {
    font-family: Arial;
    font-size: 13px;
    font-weight: 50;
}
