.wj-flexchart {
    border: none;
    padding: 0;
}

/*
.wj-label {
    font-size: 12px;
}

.wj-data-label {
    font-size: 12px;
}
*/

.wj-flexchart polyline {
    stroke-width: 3;
}

rect {
    z-index: 0;
}
