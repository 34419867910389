.wj-cell.wj-has-notes:after {
    position: absolute;
    content: '';
    width: 0;
    right: 0;
    top: -10px;
    border: 10px solid transparent;
    border-right: 10px solid red;
    opacity: .5;
}

.wj-cell.wj-has-notes:hover:after {
    opacity: 10;
}

.description-tip {
    padding: 1em 2em;
    margin: .5em;
    background-color: #F5F5F5;
    border-radius: 20px;
    font-weight: bold;
    font-size: x-large;
    color: black;
    font-size: 3px;;
    size: 3px;
}

  