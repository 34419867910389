.not-shipment  {
    color : red;
    font-weight: 800;
}

.part-shipment {
    color : darkmagenta;
    font-weight: 800;
}


.completion-shipment {
    color : green;
    font-weight: 800;
}