.wj-cell.qty-equals-blue  {
    color : blue;
    font-weight: 800;
}
.wj-cell.qty-many-orange  {
    color : orange;
    font-weight: 800;
}
.wj-cell.qty-little-red  {
    color : red;
    font-weight: 800;
}
