.dhtmlx_color_picker{
    background: #ffffff;
    border: none;
}

.dhtmlx_color_picker .dhx_palette__cell {
    border-radius: 50%;
}

.dhtmlx_color_picker .dhx_palette__cell:after {
    border-radius: 50%;
}

.dhtmlx_color_picker .dhx_colorpicker-custom-colors__picker {
    border-radius: 50%;
}

.dhtmlx_color_picker .dhx_button--view_flat.dhx_button--color_primary {
    color: #3f50b5;
    background-color: rgba(255,255,255,0);
}

.dhtmlx_color_picker .dhx_button--view_flat.dhx_button--color_primary:hover {
    color: #3f50b5;
    background-color: rgba(63,80,181,0.04);
    border-radius: 5px;
}

.dhtmlx_color_picker .dhx_colorpicker-picker__buttons .dhx_button:first-child {
    color: #f44336;
    background-color: rgba(255,255,255,0);
}


.dhtmlx_color_picker .dhx_colorpicker-picker__buttons .dhx_button:first-child:hover {
    color: #f44336;
    background-color: rgba(244, 67, 5, 0.04);
    border-radius: 5px;
}