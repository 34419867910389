.container_fluid{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction : column;
}

.nav{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav_tabs{
    border-bottom: 1px solid #ddd;
}

.nav_list{
    float: left;
    margin-bottom: -1px;
    position: relative;
    display: block;
}

.nav_list_button {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    position: relative;
    display: block;
    padding: 10px 15px;
}

.active {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

/* .tableHost{
    width: 80%;
    margin-top: 5%;
} */