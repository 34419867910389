.wj-flexgrid {
    width: 100%;
    margin: 0 0;
}

.column-picker-icon {
    cursor: pointer;
    color: #FF8754;
    margin: 3px;
}

.column-picker-div {
    display: none;
}

.wj-listbox.column-picker {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 10px;
    columns: 4;
    /* IE fallback */
    padding: 12px;
    margin-left: 12px;
    margin-top: 26px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.wj-content {
    border: none;
}

.wj-listbox .wj-listbox-item>label {
    display: block;
    margin: 0 0 3px 0;
}

.wj-listbox .wj-listbox-item.wj-state-selected {
    background: transparent;
    color: inherit;
}

.wj-listbox .wj-listbox-item:hover {
    background: rgba(0, 0, 0, .05);
}

.wj-listbox .drop-marker {
    position: absolute;
    background: #0085c7;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1000;
}

.wj-cell.wj-group {
    background: #ffffff;
}

.wj-cell.wj-header
{
    display: block;
    text-align: center;
}

.wj-cell.wj-header.wj-align-right
{
    text-align: center;
}


.wj-cell.wj-header.wj-align-left
{
    text-align: center;
}

.wj-header
{
    text-align: center;
}

.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.do-search
{
    display: block;
    position: absolute;
    background: url(search.png) left top;
    background-size: contain;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
}

.do-filter
{
    display: block;
    position: absolute;
    background: url(filter.png) left top;
    background-size: contain;
    bottom: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
}
